import React, { useState } from 'react';
import { Principal } from '@dfinity/principal';
import { HttpAgent, Actor } from '@dfinity/agent';
import { idlFactory as managementCanisterIDL } from './management_canister_idl'; // Adjust based on where you save the IDL

const TopUpComponent = () => {
  const [canisterId, setCanisterId] = useState('');
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  const onConnectionUpdate = () => {
    console.log(window.ic.plug.sessionManager.sessionData)
  }

  const handleConnectWallet = async () => {
    try {
      // Check if Plug Wallet is installed
      if (!window.ic?.plug) {
        throw new Error('Plug wallet not found. Please install the extension.');
      }

      // Request to connect the user's wallet and specify the permissions
      const whitelist = ['aaaaa-aa'];  // management canister
      const connected = await window.ic.plug.requestConnect({
        whitelist,
        //host: 'https://ic0.app'
        host: 'https://mainnet.dfinity.network',  // Host for Internet Computer mainnet
        onConnectionUpdate,
        timeout: 50000
    });

      if (!connected) {
        throw new Error('Failed to connect to Plug Wallet');
      }

      setWalletConnected(true);
      setMessage('Wallet connected successfully');
    } catch (error) {
      setMessage(`Failed to connect wallet: ${error.message}`);
    }
  };

  const isValidPrincipal = (id) => {
    try {
      Principal.fromText(id);
      return true;
    } catch {
      return false;
    }
  };

  const handleTopUp = async () => {
    try {
      if (!walletConnected) {
        setMessage('Please connect your wallet first.');
        return;
      }

      if (!isValidPrincipal(canisterId)) {
        setMessage('Invalid canister ID format.');
        return;
      }

      if (amount <= 0) {
        setMessage('Amount must be greater than zero.');
        return;
      }

      setLoading(true);
      setMessage('');

      const connected = await window.ic.plug.isConnected();
        if (!connected) await window.ic.plug.requestConnect({ whitelist: ['aaaaa-aa'], host: 'https://mainnet.dfinity.network' });

      // Create an agent to interact with the Internet Computer via Plug Wallet
    //   const agent = await window.ic.plug.createAgent({
    //     whitelist: ['aaaaa-aa'], // Management canister ID
    //     host: 'https://mainnet.dfinity.network'
    //   });

      // Fetch the root key for the agent (if necessary)
        // await window.ic.plug.agent.fetchRootKey();

      const managementCanisterId = 'aaaaa-aa';
      const managementCanister = Actor.createActor(managementCanisterIDL, {
        agent: window.ic.plug.agent,
        canisterId: managementCanisterId,
      });

      const canisterPrincipal = Principal.fromText(canisterId);
      const cycles = BigInt(amount * 10 ** 12); // Assume amount is in trillion cycles

      // Call the top-up function (deposit_cycles)
      await managementCanister.deposit_cycles(canisterPrincipal, cycles);

      setMessage(`Successfully topped up ${cycles.toString()} cycles to canister: ${canisterId}`);
    } catch (error) {
      setMessage(`Failed to top up canister: ${error.message}`);
      console.error("Error details:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <h2>Top-up Public Canister</h2>
      {!walletConnected && (
        <button onClick={handleConnectWallet}>Connect Wallet</button>
      )}

      {walletConnected && (
        <>
          <input
            type="text"
            placeholder="Canister ID"
            value={canisterId}
            onChange={(e) => setCanisterId(e.target.value)}
          />
          <input
            type="number"
            placeholder="Amount (in trillion cycles)"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
          />
          <button onClick={handleTopUp}>Top-up Canister</button>
        </>
      )}
      <p>{message}</p>
    </div>
  );
};

export default TopUpComponent;

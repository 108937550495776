import React from 'react';
import TopUpComponent from './TopUpComponent';

function App() {
  return (
    <div className="App">
      <h1>Canister Top-up Interface</h1>
      <TopUpComponent />
    </div>
  );
}

export default App;
